.react-datepicker__input-container {
  border: 1px #d4cbca solid;
  height: 45px;
  width: 200px;
  overflow: hidden;
  border-radius: 5px;
}

.react-datepicker-wrapper {
  width: auto;
  margin: 0;
  height: 40px;
}

.react-datepicker__input-container input {
  display: block;

  outline: none;
  height: 45px;
  text-align: center;
  font-family: "Oxygen";
  width: 200px;
  border-radius: 10px;
  font-size: 14px;
  color: #1b4965;
  font-weight: 700;
  border: none;
}

.react-datepicker__month-container {
  float: left;
  padding: 0px 10px;
  background-color: #f0f0f0;
}
