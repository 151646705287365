#file::file-selector-button {
  /* Hide the "No file chosen" text */
  display: none;
}

#file::-moz-file-upload-button {
  /* Hide the "No file chosen" text */
  display: none;
}

#file::-ms-reveal {
  display: none;
}
